import { Card, Col, message, Row, Spin, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { GetAllPrepQuestions } from "../api";
import { prepClassification } from "../utils/Constant";
import { useState } from "react";

const { Title } = Typography;

const questionTypes = [
  {
    label: "Neuro",
    url: "/student-prep/Neuro",
  },
  {
    label: "Chest",
    url: "/student-prep/Chest",
  },
  {
    label: "Body",
    url: "/student-prep/Body",
  },
  {
    label: "MSK",
    url: "/student-prep/MSK",
  },
];

const MedicalStudent = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState(null);

  const { mutate: prepQuestions, isLoading: prepQuestionsLoading } = GetAllPrepQuestions();

  const onTypeClick = (type) => {
    setSelectedType(type);
  };

  const onClassificationClick = (classification) => {
    if (!selectedType) {
      message.warning("Please select a question type first");
      return;
    }

    prepQuestions(
      {
        prep_type: selectedType,
        prep_classification: classification,
      },
      {
        onSuccess: (response) => {
          const responseData = response?.data?.data;
          if (Array.isArray(responseData) && responseData.length > 0) {
            sessionStorage.setItem("prepQuestions", JSON.stringify(responseData));
            navigate(`/student-prep/${selectedType}/${responseData[0].id}`);
          } else {
            message.error("No questions found");
          }
        },
      }
    );
  };

  return (
    <Spin spinning={prepQuestionsLoading}>
      <Row gutter={[16, 16]} className="mt-1">
        <Col xs={24}>
          <Title level={2}>RadMe - Virtual Reading Station for Learning Radiology</Title>
        </Col>
        <Col xs={24}>
          <Title level={3}>Question Types</Title>
        </Col>
        {questionTypes.map((item, index) => (
          <Col xs={24} md={12} lg={6} key={index}>
            <Card
              hoverable
              className="py-6 center-text"
              style={{
                border: selectedType === item.label ? "2px solid #d23f57" : "none",
                backgroundColor: "#e6e9ec",
              }}
              onClick={() => onTypeClick(item.label)}>
              <Title level={3}>{item.label}</Title>
            </Card>
          </Col>
        ))}
        <Col xs={24}>
          <Title level={3}>Prep Classification</Title>
        </Col>
        {prepClassification.map((item, index) => (
          <Col xs={24} md={12} lg={6} key={index}>
            <Card hoverable className="py-6 center-text" style={{ backgroundColor: "#e6e9ec" }} onClick={() => onClassificationClick(item.value)}>
              <Title level={3}>{item.label}</Title>
            </Card>
          </Col>
        ))}
      </Row>
    </Spin>
  );
};

export default MedicalStudent;
